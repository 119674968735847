<template>
  <div class="loansComponent">
    <van-cell-group>
      <van-cell :title="$t('BORRORSERIALBUNBER')" :value="loan.id" />
      <van-cell :title="$t('BORRORWMONEY')" :value="loan.money" />
      <van-cell :title="$t('EVERYISSUE')" :value="loan.monthly" />
      <van-cell :title="$t('BORRORWMONEYTIME')" :value="loan.months" />
    </van-cell-group>
    <van-button @click="other" color="#212A2a" class="tixian" type="primary" block>{{$t('TIX')}}</van-button>
  </div>
</template>

<script>
import { loanInfo } from '@/api/wallet'
export default {
  name: 'loansComponent',
  data () {
    return {
      loan: {}
    }
  },
  methods: {
    async getloaninfo () {
      try {
        const { data } = await loanInfo()
        if (data.code === 200) {
          // console.log(data.data)
          data.data.money = data.data.money + 'RM'
          data.data.monthly = data.data.monthly + 'RM'
          data.data.months = data.data.months + this.$t('MONTHS')
          this.loan = data.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    other () {
      this.$router.push({
        path: '/wallet/withdrawdeposit'
      })
    }
  },
  created () {
    this.getloaninfo()
  }
}
</script>

<style scoped>
.loansComponent {
  overflow: hidden;
  width: 340px;
  margin: 0 auto;
  margin-top: 20px;
  background: #fff;
}
.tixian {
  width: 75%;
  margin: 20px auto;
  /* background-color: rgb(250, 193, 6); */
}
</style>
